import { Icon } from "@iconify/react";
import {
  Button,
  Dialog,
  DialogContent,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  styled
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { candidatesList } from "../../../redux/slices/CandidatesSlices/candidatesListSlice";
import MemberDrawe from "./MemberDrawe";
import DargeAndUploadFile from "./DargeAndUploadFile";
import Placeholders from "./Placeholders";
import { getPlaceholder } from "../../../redux/slices/MailsSlices/getPlaceholderSlice";
import { composedEmails } from "../../../redux/slices/MailsSlices/composedEmailsSlice";
import {
  getTemplate,
  MailTemplate
} from "../../../redux/slices/MailsSlices/getTemplateSlice";
import { enqueueSnackbar } from "notistack";
import { getDraftDetails } from "../../../redux/slices/MailsSlices/getDraftEmailDetailsSlice";
import EcnButton from "../../StyledComponents/EcnButton";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 500,
    padding: "10px 15px",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],

    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "10px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:hover": {
        color: "rgba(0, 52, 187, 1)",
        background: "rgba(0, 52, 187, 0.10)",
        borderRadius: "10px",
        fontSize: "14px !important",
        fontWeight: "500"
      }
    }
  }
}));

const Compose: React.FC<{
  setRowSelectionModel: any;
  rowSelectionModel: any;
  setClickDraft: any;
  setActiveStep: any;
}> = ({
  setRowSelectionModel,
  rowSelectionModel,
  setClickDraft,
  setActiveStep
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const inputRef = useRef<HTMLInputElement>(null);

    const id = searchParams.get("id");
    const email_id = searchParams.get("email_id") || "";
    const back = searchParams.get("back-to") || "";
    const st = searchParams.get("status") || -1;
    const applicants = searchParams.get("applicants") || "";

    const { getJobsListData } = useAppSelector((state: any) => state.jobsList);
    const { userInfo, errorData } = useAppSelector(
      (state: any) => state.userDetails
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openMember, setOpenMember] = useState(false);
    const open = Boolean(anchorEl);
    const quillRef = useRef<ReactQuill>(null);

    const [rows, setRows] = useState<any>();
    const [seeAll, setSeeAll] = useState<any>(false);
    const [subject, setSubject] = useState<any>("");
    const [emailText, setEmailText] = useState<any>("");
    const [openAttach, setOpenAttach] = useState<any>();
    const [smallFiles, setSmallFiles] = useState<any>([]);
    const [allFiles, setAllFiles] = useState<any>([]);
    const [fileList, setFileList] = useState<any>([]);
    const [openPlaceHolder, setOpenPlaceHolder] = useState<any>(false);
    const [selectedUser, setSelectedUser] = useState<any>([]);
    const [selectedTemplate, setSelectedTemplate] =
      useState<MailTemplate["id"]>();
    const [click, setClick] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [isTouch, setIsTouch] = useState(false);

    const [
      discardOnTemplateSelectionDialog,
      setDiscardOnTemplateSelectionDialog
    ] = useState<MailTemplate["id"] | undefined>();

    const [value, setValue] = React.useState("");

    const { candidatesListData, success } = useAppSelector(
      (state: any) => state.candidateLists
    );

    const { placeholderData } = useAppSelector(
      (state: any) => state.placeholderList
    );
    const { templateData } = useAppSelector((state) => state.templateList);

    const {
      composedData,
      success: composedDataSuccess,
      loading,
      error
    } = useAppSelector((state: any) => state.composedEmail);

    const { draftDetailsData } = useAppSelector(
      (state: any) => state.getDraftEmailDetails
    );

    useEffect(() => {
      dispatch(getPlaceholder());
    }, []);

    useEffect(() => {
      dispatch(getTemplate());
    }, []);

    useEffect(() => {
      dispatch(getDraftDetails(email_id));
    }, []);

    useEffect(() => {
      if (composedData && click) {
        enqueueSnackbar("Email sent successfully!", {
          variant: "success"
        });
        navigate("?view=sent");
        setActiveStep(1);
        setClickDraft(false);
      }
    }, [composedData]);

    useEffect(() => {
      if (error && click) {
        enqueueSnackbar("Something went wrong", {
          variant: "error"
        });
        navigate("?view=sent");
        setActiveStep(1);
        setClickDraft(false);
      }
    }, [error]);

    useEffect(() => {
      if (id === null && getJobsListData) {
        navigate(
          `?view=compose&id=${getJobsListData?.result[0]?._id}&back-to=${back}`
        );
      }
    }, [getJobsListData]);

    useEffect(() => {
      if (id) {
        setSelectedUser([]);
        dispatch(candidatesList({ id, st: Number(st), limit: 10000 }));
      }
    }, [id, st]);

    useEffect(() => {
      if (candidatesListData) {
        const data1 = candidatesListData?.applications?.map((user: any) => {
          return {
            key: user._id ?? null,
            _id: user.created_by?._id ?? null,
            name: `${user.created_by?.first_name ?? "N/A"} ${user.created_by?.last_name ?? "N/A"
              }`,
            designation: user.work_experiences
              ? user.work_experiences[0]?.position
              : "",
            email: user.created_by?.email ?? "",
            ai: user.avg_relevancy_score
          };
        });

        setRows(data1);
      }
    }, [candidatesListData]);

    useEffect(() => {
      if (applicants && candidatesListData?.applications) {
        const selected = applicants.split(",");
        const rows = candidatesListData?.applications?.filter((row: any) =>
          selected.includes(row._id)
        );
        setSelectedUser(rows);
        setRowSelectionModel(selected);
      }
    }, [applicants, candidatesListData]);

    useEffect(() => {
      if (draftDetailsData && email_id != "") {
        setSubject(draftDetailsData?.subject);
        setEmailText(draftDetailsData?.body);
        setFileList(draftDetailsData?.attachments);
      }
    }, [draftDetailsData]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const statusHandler = (e: any) => {
      navigate(
        `?view=compose&id=${e}${email_id === "" ? "" : `&email_id=${email_id}`
        }&back-to=${back}`
      );
      setAnchorEl(null);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const toggleDrawer = () => {
      setOpenMember(false);
      setOpenAttach(false);
      setOpenPlaceHolder(false);

      const userIds = selectedUser?.map((user: any) => user?.created_by?._id);
      setRowSelectionModel(userIds);
    };

    const selectHandler = () => {
      setIsTouch(true);
      setOpenMember(false);
      const filteredData = candidatesListData?.applications?.filter((item: any) =>
        rowSelectionModel.includes(item?.created_by?._id)
      );
      setSelectedUser(filteredData);
    };

    const openDrawerHandler = () => {
      const userIds = selectedUser?.map((user: any) => user?.created_by?._id);
      setRowSelectionModel(userIds);
      setOpenMember(true);
    };

    const expendedHandler = () => {
      setSeeAll(true);
    };

    const removeUserHandler = (id: any) => {
      const filteredArray = selectedUser.filter(
        (item: any) => item?.created_by?._id !== id
      );
      setSelectedUser(filteredArray);
    };

    const subjectHandler = (event: any) => {
      // Get the input value
      setIsTouch(true);
      let inputText = event.target.value;

      // Limit the input length to 100 characters
      if (inputText.length > 140) {
        inputText = inputText.slice(0, 140);
      }

      // Update the state with the limited input value
      setSubject(inputText);
    };

    const removeHandler = (indexToRemove: number) => {
      setAllFiles((prevFiles: any) =>
        prevFiles.filter((_: any, index: any) => index !== indexToRemove)
      );
      setSmallFiles((prevFiles: any) =>
        prevFiles.filter((_: any, index: any) => index !== indexToRemove)
      );
      setFileList((prevFiles: any) =>
        prevFiles.filter((_: any, index: any) => index !== indexToRemove)
      );
    };

    const placeHandler = () => {
      if (isFocus) {
        if (inputRef && inputRef.current) {
          const inputField = inputRef.current;
          const cursorIndex = inputField.selectionStart ?? 0; // Default to 0 if selectionStart is null
          const currentValue = inputField.value;
          const textToInsert = ` {{${value}}}`;

          // Insert text into the input field at the cursor position
          const newValue =
            currentValue.slice(0, cursorIndex) +
            textToInsert +
            currentValue.slice(cursorIndex);

          // Update the state with the new value
          setSubject(newValue);

          // Calculate the new cursor position after inserted text
          const newCursorIndex = cursorIndex + textToInsert.length;

          // Set cursor position after inserted text
          inputField.setSelectionRange(newCursorIndex, newCursorIndex);

          setOpenPlaceHolder(false); // Close the placeholder drawer
          setIsFocus(false);
        }
      } else {
        if (quillRef && quillRef.current) {
          const editor = quillRef.current.getEditor();
          const selection = editor.getSelection(true);
          if (selection) {
            const textToInsert = ` {{${value}}}`;
            editor.insertText(selection.index, textToInsert);
            setOpenPlaceHolder(false);
          }
        }
      }
    };

    const TemplateHandler = () => {
      const foundItem = templateData?.find(
        (item: any) => item.id === selectedTemplate
      );
      setSubject(foundItem?.subject);
      setEmailText(foundItem?.body);
      setFileList(foundItem?.attachments);

      // if (quillRef && quillRef.current) {
      //   const editor = quillRef.current.getEditor();
      //   const selection = editor.getSelection(true);
      //   if (selection) {
      //     const textToInsert = ` ${foundItem?.body}`;
      //     editor.insertText(selection.index, textToInsert);
      //     setOpenPlaceHolder(false);
      //   }
      // }
    };

    const sendHandler = () => {
      let tempElement = document.createElement("div");
      tempElement.innerHTML = emailText;
      let pTags = Array.from(tempElement.querySelectorAll("p"));

      pTags.forEach((pTag) => {
        // Add margin="0px" attribute to the <p> tag
        pTag.setAttribute("style", "margin: 0px auto;");
        if (pTag.querySelector("br")) {
          while (pTag.firstChild) {
            pTag?.parentNode?.insertBefore(pTag.firstChild, pTag);
          }
          pTag?.parentNode?.removeChild(pTag);
        }
      });

      let updatedEmailText = tempElement.innerHTML;
      setClick(true);
      dispatch(
        composedEmails({
          email_id: "",
          __comment__: "Keep the email id empty for new emails",
          job_id: id,
          user_ids: rowSelectionModel,
          sender_name: userInfo?.first_name + " " + userInfo?.last_name,
          subject: subject,
          body: updatedEmailText,
          attachments: smallFiles,
          is_draft: false
        })
      );
    };

    const focusInput = () => {
      inputRef?.current?.focus();
      setIsFocus(true);
    };

    const backHandler = () => {
      if (
        (emailText?.length > 1 ||
          subject?.length > 1 ||
          rowSelectionModel?.length > 1) &&
        isTouch
      ) {
        setClickDraft(true);

        dispatch(
          composedEmails({
            email_id: email_id,
            __comment__: "Keep the email id empty for new emails",
            job_id: id,
            user_ids: rowSelectionModel,
            sender_name: userInfo?.first_name + " " + userInfo?.last_name,
            subject: subject,
            body: emailText,
            attachments: smallFiles,
            is_draft: true
          })
        );
      }
      navigate(`?view=${back}`);
    };
    const focusQuillEditor = (e: React.MouseEvent<HTMLDivElement>) => {
      setIsTouch(true);
      setIsFocus(false);
      const target = e.target as HTMLElement;

      const isQuillElement = target.classList.contains("ql-tooltip"); // Check if clicked inside the Quill editor
      const isExcludedElement =
        target.tagName === "INPUT" || target.tagName === "A"; // Check if clicked inside an input or a tag

      // If clicked inside the Quill editor or an excluded element, don't focus the editor
      if (isQuillElement || isExcludedElement) {
        return;
      }

      // Otherwise, focus the Quill editor
      if (quillRef && quillRef.current) {
        quillRef.current.focus();
      }
    };

    const bodyTextHandler = (e: any) => {
      setEmailText(e);
    };

    const templateSelectionHandler = (templateId: MailTemplate["id"]) => {
      if (emailText == "") {
        applyTemplateById(templateId);
      } else {
        setDiscardOnTemplateSelectionDialog(templateId);
      }
    };

    const applyTemplateById = (templateId: MailTemplate["id"]) => {
      //Sets subject and body
      if (!templateData) return;
      const selected = templateData?.find(
        (item: MailTemplate) => item.id == templateId
      );
      if (!selected) return;
      setEmailText(selected.body);
      setSubject(selected.subject);
      setSelectedTemplate(templateId);
    };

    return (
      <div className="composed-email">
        <div className="top">
          <p className="back" onClick={backHandler}>
            {" "}
            <Icon icon="carbon:arrow-left" /> Compose email
          </p>
          <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
            <Button onClick={() => navigate("?view=inbox")}>Discard</Button>
          </div>
        </div>

        <div className="select-job">
          <p>Job name</p>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            className="menu-button"
            endIcon={
              !open ? (
                <Icon
                  icon="lucide:chevron-down-circle"
                  color="rgba(0, 52, 187, 1)"
                />
              ) : (
                <Icon
                  icon="lucide:chevron-up-circle"
                  color="rgba(0, 52, 187, 1)"
                />
              )
            }
          >
            {
              getJobsListData?.result?.find((item: any) => item?._id === id)
                ?.job_title
            }
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button"
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{ padding: "20px" }}
            PaperProps={{ style: { maxHeight: "400px" } }} // Set max height here
          >
            {getJobsListData?.result?.map((item: any) => (
              <MenuItem
                onClick={() => statusHandler(item?._id)}
                sx={
                  item?._id === id
                    ? {
                      background: "rgba(0, 52, 187, 0.08)!important",
                      borderRadius: "9px",
                      marginBottom: "5px",
                      fontSize: "14px !important",
                      fontWeight: "500",
                      color: "#0034bb !important",
                      padding: "8px 15px"
                    }
                    : {
                      background: "#fff !important",
                      marginBottom: "5px",
                      fontSize: "14px !important",
                      color: "rgba(33, 33, 33, 0.8)",
                      fontWeight: "500",
                      padding: "8px 15px",
                      "&:hover": {
                        backgroundColor: "rgba(0, 52, 187, 0.08) !important",
                        color: "rgba(0, 52, 187, 1)",
                        borderRadius: "9px"
                      }
                    }
                }
              >
                {item?.job_title}
              </MenuItem>
            ))}
          </StyledMenu>
        </div>

        <div className="member-lists">
          <p>Sent to</p>
          <div className="job-member">
            {!seeAll
              ? selectedUser?.map(
                (item: any, index: any) =>
                  index < 3 && (
                    <div
                      className="item-chip"
                      onClick={() => removeUserHandler(item?.created_by?._id)}
                    >
                      <p>{item?.created_by?.first_name}</p>
                      <Icon
                        icon="carbon:close"
                        color="rgba(33, 33, 33, 1)"
                        height={16}
                        width={16}
                      />
                    </div>
                  )
              )
              : selectedUser?.map((item: any, index: any) => (
                <div
                  className="item-chip"
                  onClick={() => removeUserHandler(item?.created_by?._id)}
                >
                  <p>{item?.created_by?.first_name}</p>
                  <Icon
                    icon="carbon:close"
                    color="rgba(33, 33, 33, 1)"
                    height={16}
                    width={16}
                  />
                </div>
              ))}
            {!seeAll ? (
              selectedUser?.length > 3 && (
                <div className="item-chip number" onClick={expendedHandler}>
                  <Icon
                    icon="lucide:plus"
                    color="rgba(0, 52, 187, 1)"
                    height={14}
                    width={14}
                  />
                  <p>{selectedUser?.length - 3}</p>
                </div>
              )
            ) : (
              <div
                className="item-chip number"
                onClick={() => setSeeAll(!seeAll)}
                style={{
                  border: "1px solid rgba(0, 52, 187, 0.20)",
                  background: "transparent",
                  padding: "1px 14px"
                }}
              >
                <p>show less</p>
              </div>
            )}
            <Button onClick={openDrawerHandler}>
              <Icon icon="lucide:plus" width={18} height={18} />{" "}
              {selectedUser?.length > 1
                ? "Select more applicant"
                : "Select applicant"}
            </Button>
          </div>
        </div>

        <div className="subject" onClick={focusInput}>
          <p>Subject</p>
          <input
            type="text"
            ref={inputRef}
            onChange={subjectHandler}
            value={subject}
            placeholder="Enter subject"
          />
        </div>

        <div className="email-body-template-and-buttons">
          <div className="text-body" onClick={focusQuillEditor}>
            <div className="quill">
              <ReactQuill
                onChange={bodyTextHandler}
                value={emailText}
                ref={quillRef}
                theme="snow"
                bounds={".app"}
                className="quill-candidatesdetail"
                modules={modules}
                formats={formats}
              />
              <div className="attach">
                {fileList?.map((item: any, index: any) => (
                  <div className="file">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px"
                      }}
                    >
                      {item.attachmentType ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                        <IconButton>
                          <Icon icon="vscode-icons:file-type-word" />
                        </IconButton>
                      ) : item.attachmentType === "application/pdf" ? (
                        <IconButton>
                          <Icon icon="vscode-icons:file-type-pdf2" />
                        </IconButton>
                      ) : item.attachmentType ===
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                        <IconButton>
                          <Icon icon="vscode-icons:file-type-excel" />
                        </IconButton>
                      ) : item.attachmentType === "image/png" ||
                        item.attachmentType === "image/jpeg" ? (
                        <IconButton>
                          <Icon icon="lucide:image" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M10.0013 1.33203H4.0013C3.64768 1.33203 3.30854 1.47251 3.05849 1.72256C2.80844 1.9726 2.66797 2.31174 2.66797 2.66536V13.332C2.66797 13.6857 2.80844 14.0248 3.05849 14.2748C3.30854 14.5249 3.64768 14.6654 4.0013 14.6654H12.0013C12.3549 14.6654 12.6941 14.5249 12.9441 14.2748C13.1942 14.0248 13.3346 13.6857 13.3346 13.332V4.66536L10.0013 1.33203Z"
                              stroke="#0034BB"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.33594 1.33203V3.9987C9.33594 4.35232 9.47641 4.69146 9.72646 4.94151C9.97651 5.19156 10.3156 5.33203 10.6693 5.33203H13.3359"
                              stroke="#0034BB"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </IconButton>
                      )}
                      <p>{item?.attachmentFileName}</p>
                    </div>
                    <Icon
                      icon="lucide:x-circle"
                      height={24}
                      width={24}
                      onClick={() => removeHandler(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="templates-sidebar">
            <div className="templates-header">
              <div className="title">Templates</div>
              <div
                className="create-new-link"
                onClick={() => {
                  navigate("/mails/template?view=create");
                }}
              >
                Create New
              </div>
            </div>
            <div className="templates-list-container">
              {templateData?.map((item: MailTemplate) => {
                const selected = item.id == selectedTemplate;
                return (
                  <div
                    className={`template-list-item ${selected ? "template-item-selected" : ""
                      }`}
                    key={item.id}
                    onClick={() => templateSelectionHandler(item.id)}
                  >
                    <div className="template-title">{item.title}</div>
                    <div className="template-body">
                      {item.body.slice(0, 100)}
                      {item.body.length > 100 ? "..." : ""}
                    </div>
                    {selected && (
                      <div className="selected-state">
                        <div className="text">Selected</div>
                        <Icon icon="gg:check-o" width={18} height={18} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="buttons">
            <div className="button-left">
              <Button onClick={() => setOpenAttach(true)}>
                <Icon icon="fluent:attach-20-filled" /> Attach
              </Button>
              <Button
                className="placeholder"
                onClick={() => {
                  setOpenPlaceHolder(true);
                  setValue("");
                }}
              >
                Placeholder
              </Button>
            </div>
            <div className="button-right">
              <Button
                variant="contained"
                onClick={sendHandler}
                disabled={
                  rowSelectionModel?.length === 0 ||
                  subject?.length === 0 ||
                  emailText?.length == 0 ||
                  loading
                }
              >
                <Icon icon="lucide:send-horizontal" height={24} width={24} /> Send
              </Button>
            </div>
          </div>
        </div>

        <Dialog
          open={discardOnTemplateSelectionDialog ? true : false}
          onClose={() => {
            setDiscardOnTemplateSelectionDialog(undefined);
          }}
          className="apply-template-confirmation-dialog"
          sx={{
            "& .MuiDialog-container": {
              background: "rgba(0,0,0,0.3)",
              "& .MuiPaper-root": {
                padding: "20px 80px",
                borderRadius: "7px",
                maxWidth: "800px" // Set your width here
              }
            }
          }}
        >
          <DialogContent>
            <h2
              style={{
                fontSize: "25px",
                fontWeight: "400",
                color: "rgba(33, 33, 33, 1)"
              }}
            >
              Are you sure you want to discard ?{" "}
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "30px",
                marginTop: "20px"
              }}
            >
              <EcnButton
                variant="outlined"
                className="cancle-apply-template-btn"
                sx={{ maxWidth: "196px !important" }}
                onClick={() => {
                  setDiscardOnTemplateSelectionDialog(undefined);
                }}
                style={{
                  fontSize: "18px",
                  fontWeight: "600"
                }}
              >
                Cancel
              </EcnButton>
              <EcnButton
                variant="outlined"
                className="confirm-apply-template-btn"
                onClick={() => {
                  if (discardOnTemplateSelectionDialog) {
                    applyTemplateById(discardOnTemplateSelectionDialog);
                  }
                  setDiscardOnTemplateSelectionDialog(undefined);
                }}
                style={{
                  fontSize: "18px",
                  fontWeight: "600"
                }}
              >
                Yes
              </EcnButton>
            </div>
          </DialogContent>
        </Dialog>

        <Drawer
          anchor="right"
          open={openMember}
          onClose={toggleDrawer}
          className="open-member"
        >
          <MemberDrawe
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            rows={rows}
            setOpenMember={setOpenMember}
            setRows={setRows}
          />

          <div className="member-list-bottom">
            <Button className="cancel" onClick={toggleDrawer}>
              Cancel
            </Button>
            <Button className="select" onClick={selectHandler}>
              Select participants
            </Button>
          </div>
        </Drawer>

        <Drawer
          anchor="right"
          open={openAttach}
          onClose={toggleDrawer}
          className="open-attachment"
        >
          <div className="top" onClick={() => setOpenAttach(false)}>
            <IconButton>
              <Icon icon="material-symbols:arrow-back" />{" "}
            </IconButton>
            <IconButton>
              <Icon icon="ic:baseline-close" />{" "}
            </IconButton>
          </div>
          <h1 className="title">Attach document</h1>
          <DargeAndUploadFile
            setOpenAttach={setOpenAttach}
            setSmallFiles={setSmallFiles}
            smallFiles={smallFiles}
            setAllFiles={setAllFiles}
            allFiles={allFiles}
            setFileList={setFileList}
          />
        </Drawer>

        <Drawer
          anchor="right"
          open={openPlaceHolder}
          onClose={toggleDrawer}
          className="open-placeholder"
        >
          <div className="top">
            <IconButton onClick={() => setOpenPlaceHolder(false)}>
              <Icon icon="material-symbols:arrow-back" />{" "}
            </IconButton>
            <IconButton
              onClick={() => setOpenPlaceHolder(false)}
              style={{ marginRight: "30px" }}
            >
              <Icon icon="ic:baseline-close" />{" "}
            </IconButton>
          </div>
          <h1 className="title">Select Placeholder</h1>
          <p className="total">{placeholderData?.length} placeholder available</p>
          <Placeholders setValue={setValue} value={value} />
          <div className="member-list-bottom">
            <Button className="cancel" onClick={toggleDrawer}>
              Cancel
            </Button>
            <Button className="select" onClick={placeHandler}>
              Use Placeholder
            </Button>
          </div>
        </Drawer>
      </div>
    );
  };

export default Compose;

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"]
  ]
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image"
];
