import { Icon } from "@iconify/react";
import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import TableData from "./TableData";

const NavTab: React.FC<{
  setActiveStep: any;
  activeStep: any;
  setData: any;
  data: any;
}> = ({ setActiveStep, activeStep, setData, data }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || "";
  const id = searchParams.get("id") || "";

  const { inboxData, loading } = useAppSelector(
    (state: any) => state.getInboxList
  );

  const { sentData } = useAppSelector((state: any) => state.getSentList);
  const { draftData } = useAppSelector((state: any) => state.getDraftList);
  const { staredData } = useAppSelector((state: any) => state.getStaredList);
  const { deleteData } = useAppSelector((state: any) => state.getDeleteList);

  const [customData, setCustomData] = useState<any>();

  const handleChange = (event: any, newValue: any) => {
    navigate(
      `?view=${
        newValue === 0
          ? "inbox"
          : newValue === 1
          ? "sent"
          : newValue === 2
          ? "draft"
          : newValue === 3
          ? "starred"
          : "bin"
      }${id==="" ? "" : `&id=${id}`}`
    );
    setActiveStep(newValue);
  };

  useEffect(() => {
    if (activeStep === 0) {
      const uniqueData = inboxData?.result?.reduce(
        (uniqueArray: any, currentItem: any) => {
          // Find the index of the existing item with the same subject, if any
          const existingIndex = uniqueArray.findIndex(
            (item: any) => item.subject === currentItem.subject
          );

          // If no existing item found, or if the current item is newer (determined by createdAt timestamp)
          if (
            existingIndex === -1 ||
            currentItem.createdAt > uniqueArray[existingIndex].createdAt
          ) {
            // If no existing item found, push the current item
            if (existingIndex === -1) {
              uniqueArray.push(currentItem);
            } else {
              // Replace existing item with the current item
              uniqueArray[existingIndex] = currentItem;
            }
          }

          return uniqueArray;
        },
        [] // Initial value of uniqueArray
      );

     
      setData(uniqueData);
      setCustomData(inboxData);
    }

    if (activeStep === 1) {
      const uniqueData = sentData?.result?.reduce(
        (uniqueArray: any, currentItem: any) => {
          // Strip "Re:" prefix from the subject for comparison
          const currentSubject = currentItem.subject.replace(/^Re: /, "");

          // Check if there is already an item with the same subject (after removing "Re:") in uniqueArray
          const existingItem = uniqueArray.find(
            (item: any) => item.subject.replace(/^Re: /, "") === currentSubject
          );

          // If not found, add the current item to uniqueArray
          if (!existingItem) {
            uniqueArray.push(currentItem);
          }

          return uniqueArray;
        },
        []
      );

      setData(uniqueData);
      setCustomData(sentData);
    }

    if (activeStep === 2) {
      const uniqueData = draftData?.result?.reduce(
        (uniqueArray: any, currentItem: any) => {
          // Check if there is already an item with the same subject in uniqueArray
          const existingItem = uniqueArray.find(
            (item: any) => item.subject === currentItem.subject
          );

          // If not found, add the current item to uniqueArray
          if (!existingItem) {
            uniqueArray.push(currentItem);
          }

          return uniqueArray;
        },
        []
      );
      setData(uniqueData);
      setCustomData(draftData);
    }
    if (activeStep === 3) {
      const uniqueData = staredData?.result?.reduce(
        (uniqueArray: any, currentItem: any) => {
          // Check if there is already an item with the same subject in uniqueArray
          const existingItem = uniqueArray.find(
            (item: any) => item.subject === currentItem.subject
          );

          // If not found, add the current item to uniqueArray
          if (!existingItem) {
            uniqueArray.push(currentItem);
          }

          return uniqueArray;
        },
        []
      );
      setData(uniqueData);
      setCustomData(staredData);
    }
    if (activeStep === 4) {
      const uniqueData = deleteData?.result?.reduce(
        (uniqueArray: any, currentItem: any) => {
          // Check if there is already an item with the same subject in uniqueArray
          const existingItem = uniqueArray.find(
            (item: any) => item.subject === currentItem.subject
          );

          // If not found, add the current item to uniqueArray
          if (!existingItem) {
            uniqueArray.push(currentItem);
          }

          return uniqueArray;
        },
        []
      );
      setData(uniqueData);
      setCustomData(deleteData);
    }
  }, [
    activeStep,
    inboxData,
    sentData,
    draftData,
    staredData,
    deleteData,
    view,
  ]);

  return (
    <div className="nav-tab">
      <div className={"slider"}>
        <Tabs value={activeStep} onChange={handleChange} className="tabs">
          <Tab
            label={
              <p>
                <Icon icon="lucide:inbox" width={24} height={24} /> Inbox
              </p>
            }
          />
          <Tab
            label={
              <p>
                <Icon icon="lucide:send-horizontal" width={24} height={24} />{" "}
                Sent
              </p>
            }
          />
          <Tab
            label={
              <p>
                <Icon icon="lucide:file" width={24} height={24} /> Draft
              </p>
            }
          />
          <Tab
            label={
              <p>
                <Icon icon="lucide:star" width={24} height={24} /> Starred
              </p>
            }
          />
          <Tab
            label={
              <p>
                <Icon icon="lucide:trash-2" width={24} height={24} /> Bin
              </p>
            }
          />
        </Tabs>

        {activeStep === 0 && (
          <div className="body">
            {" "}
            <TableData
              data={data}
              activeStep={activeStep}
              customData={customData}
            />
          </div>
        )}
        {activeStep === 1 && (
          <div className="body">
            {" "}
            <TableData
              data={data}
              activeStep={activeStep}
              customData={customData}
            />
          </div>
        )}
        {activeStep === 2 && (
          <div className="body">
            {" "}
            <TableData
              data={data}
              activeStep={activeStep}
              customData={customData}
            />
          </div>
        )}
        {activeStep === 3 && (
          <div className="body">
            {" "}
            <TableData
              data={data}
              activeStep={activeStep}
              customData={customData}
            />
          </div>
        )}

        {activeStep === 4 && (
          <div className="body">
            {" "}
            <TableData
              data={data}
              activeStep={activeStep}
              customData={customData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NavTab;
